import * as React from "react";
import { Link } from "gatsby";

import {
  Box,
  Heading,
  Text,
  useColorMode,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CheckIcon, ChevronLeftIcon } from "@chakra-ui/icons";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

// markup
const Confirmation = () => {
  const { colorMode } = useColorMode();
  return (
    <Box
      //  bg="gray.50"
      pt="40px"
      maxW={{ base: "xl", md: "4xl" }}
      mx="auto"
      px={{ base: "6", md: "40px" }}
    >
      <Header />
      <Box mt="100px">
        <Link to="/">
          <Text
            mt="40px"
            mb="20px"
            size="xl"
            fontWeight="extrabold"
            maxW="2xl"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
            color="gray.500"
          >
            <ChevronLeftIcon />
            Retour
          </Text>
        </Link>

        <Text
          fontSize={{ base: "44px", md: "68px" }}
          fontWeight="extrabold"
          lineHeight="1.1"
          bgGradient="linear(to-l, #748DC0 , #BAEC76)"
          bgClip="text"
        >
          Merci
        </Text>
        <Text fontSize="xl" my="40px">
          Nous reviendrons très vite vers vous
        </Text>
      </Box>
      <Footer />
    </Box>
  );
};

export default Confirmation;
